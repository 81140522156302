@import './reset.scss';
@import './fonts.scss';
@import './keyframes.scss';
@import './audio.icon.scss';

$rhap_theme-color: #ffffff !default;
$rhap_background-color: rgba(255, 255, 255, 0) !default;
$rhap_bar-color: #e4e4e4 !default;
$rhap_time-color: rgb(255, 255, 255) !default;
$rhap_font-family: inherit !default;

@import './../node_modules/react-h5-audio-player/src/styles.scss';

html {
  margin: 0;
  padding: 0;
  height: 100%;

  body {
    scroll-snap-type: y proximity;
    scroll-behavior: auto !important;
    overflow-x: hidden !important;

    :focus-visible {
      outline: none !important;
    }

    
    #waveform {
      wave {
        overflow: hidden !important;
        // filter: grayscale(10);
        z-index: -50;
        //opacity: 0.5;
      }
    }

    .rhap_progress-indicator {
      background-color: #22d3ee !important;
      box-shadow: none !important;
    }

    .rhap_main {
      flex-direction: column-reverse !important;

      .rhap_controls-section {
        .rhap_additional-controls {
          display: none !important;
        }

        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        margin: 20px auto !important;
      }

      .rhap_volume-controls {
        display: none !important;
      }

      .rhap_main-controls {
        button.rhap_skip-button {
          display: none !important;
        }

        button.rhap_play-pause-button {
          font-size: 85px !important;
          width: 85px !important;
          height: 85px !important;
        }
      }
    }

  }
}
