.icon {
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    width: 13px;
    height: 13px;
    margin-right: 8px;

    &.animated {
        &>span {
            animation: bounce 2.2s ease infinite alternate;
        }
    }


    &>span {

        width: 3px;
        height: 100%;
        border-radius: 3px;
        transform-origin: bottom;
        transition: opacity 0.3s ease-out;
        content: '';

        &:nth-of-type(2) {
            animation-delay: -2.2s;
        }

        &:nth-of-type(3) {
            animation-delay: -3.7s;
        }
    }
}



@keyframes bounce {
    10% {
        transform: scaleY(0.3);
    }

    30% {
        transform: scaleY(1);
    }

    60% {
        transform: scaleY(0.5);
    }

    80% {
        transform: scaleY(0.75);
    }

    100% {
        transform: scaleY(0.6);
    }
}